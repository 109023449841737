import React from 'react'
import Contact from '../components/Contact'
import PageHeader from '../components/PageHeader'

function ContactPage() {
  return (
    <>
        <PageHeader pageName='Contact' pageHeader='Contact Us' />
        <Contact />
    </>
  )
}

export default ContactPage