import React from 'react'
import logo from '../img/logo.png'
function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a style={{margin: '0 -40px'}} href="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
            <img width={180} src={logo} alt='logo'/>
            {/* <h2 className="m-0 text-primary"><i className="fa fa-car me-3"></i>A & W</h2> */}
        </a>
        <button onClick={() => {
                const element = document.getElementById('navbarCollapse')
                if (element.classList.contains('show')) {
                        element.classList.remove('show')
                } else {
                        element.classList.add('show')
                }
            }} 
            type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <a href="/" className={`nav-item nav-link ${window.location.pathname === '/' && 'active'}`}>Home</a>
                <a href="/about" className={`nav-item nav-link ${window.location.href.includes('/about') && 'active'}`}>About</a>
                <a href="/services" className={`nav-item nav-link ${window.location.href.includes('/services') && 'active'}`}>Services</a>
                <a href="/contact" className={`nav-item nav-link ${window.location.href.includes('/contact') && 'active'}`}>Contact</a>
            </div>
            {/* <a href="" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Get A Quote<i className="fa fa-arrow-right ms-3"></i></a> */}
        </div>
    </nav>
  )
}

export default NavBar