import React from 'react'

function Footer() {
  return (
    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>USA, Wisconsin, Milwaukee</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>414 249-4993</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@awautoservice.net</p>
                    <div className="d-flex pt-2">
                        {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a> */}
                        <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=100089632368804"><i className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a> */}
                        {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Opening Hours</h4>
                    <h6 className="text-light">Monday - Friday:</h6>
                    <p className="mb-4">08.00 AM - 05.30 PM</p>
                    <h6 className="text-light">Saturday - Sunday:</h6>
                    <p className="mb-0">09.00 AM - 01.00 PM</p>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="text-light mb-4">Services</h4>
                    <p className="mb-4">Diagnostic Test</p>
                    <p className="mb-4">Engine Servicing</p>
                    <p className="mb-4">Tires Replacement</p>
                    <p className="mb-4">Oil Changing</p>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="http://awautoservice.net">A & W Auto Services</a>, All Right Reserved.

                        
                        Designed By Musab Aldawod
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer