import React from 'react'
import BookingForm from '../components/BookingForm'
import PageHeader from '../components/PageHeader'
import Services from '../components/Services'

function ServicesPage() {
  return (
    <>
        <PageHeader pageName='Services' pageHeader='Services' />
        <Services />
        <BookingForm />
    </>
  )
}

export default ServicesPage