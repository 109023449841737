import './App.css';
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import CustomeRoutes from './routes/Routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <>
    <BrowserRouter>
      <TopBar/>
      <NavBar />
      <CustomeRoutes />
      <Footer />
    </BrowserRouter>
    {/* <Reviews /> */}
    </>
  );
}

export default App;
