import React from 'react'
import PageHeader from '../components/PageHeader'
import Services from '../components/Services'
import About from '../components/About'
import FactStates from '../components/FactStates'

function AboutPage() {
  return (
    <>
        <PageHeader pageName='About' pageHeader='About Us'/>
        <Services />
        <About />
        <FactStates />
    </>
  )
}

export default AboutPage