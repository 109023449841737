import React from 'react'
import carousel from '../img/carousel-bg-1.jpg'

function PageHeader({pageHeader, pageName}) {
  return (
    <div className="container-fluid page-header mb-5 p-0" style={{backgroundImage: `url(${carousel})`}}>
        <div className="container-fluid page-header-inner py-5">
            <div className="container text-center">
                <h1 className="display-3 text-white mb-3 animated slideInDown">{pageHeader}</h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center text-uppercase">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item text-white active" aria-current="page">{pageName}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
  )
}

export default PageHeader