import moment from 'moment/moment'
import React, { useState } from 'react'

function BookingForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        request: '',
        selectedService: ''
    })

    const handleChange = (e) => {
        if(e.target.id === 'date') {
            setFormData({...formData, [e.target.id] : moment(e.target.value).format('yyyy-MM-dd')})
        }
        setFormData({...formData, [e.target.id] : e.target.value})
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {
            name,
            email,
            date,
            selectedService,
            request
        } = formData
        console.log(formData)
        if (name === "" || email === ""|| date === "" || selectedService === "") {
            console.log("first")
            //show error
        } else {
            // call email
            console.log("first")
            // const data = {
            //     personalizations: [{
            //       to: [{ email: "info@awautoservice.net" }]
            //     }],
            //     from: { email: "info@awautoservice.net" },
            //     subject: "Service Request",
            //     content: [{
            //       type: "text/plain",
                //   value: `Hi A & W, 
                //   This email is to confirm that '${name} have requested ${selectedService} service
                //   ${request && `with a special request of ${request}`}
                //   on ${date}
                //   Please make sure to contact this person on this email ${email}.`
            //     }]
            //   }
            // const msg = {
            //     to: "info@awautoservice.net",
            //     from: email,
            //     subject: "Request a service",
            //     text:  `Hi A & W,
            //         This email is to confirm that '${name} have requested ${selectedService} service
            //         ${request && `with a special request of ${request}`}
            //         on ${date}
            //         Please make sure to contact this person on this email ${email}.`,
            //     html: <p></p>,
            //   };
            //   sgMail.send(msg).then(() => {
            //     console.log('Email sent');
            //   }).catch(error => {
            //     console.error(error);
            //   });
              //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
              // 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
              //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
              // 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 fullllll
            //   https://api.emailjs.com/api/v1.0/email/send
            //   const res = await fetch('https://api.sendgrid.com/v3/mail/send', {
            //       method: 'POST',
            //       headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer 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`
            //       },
            //       body: JSON.stringify(data)
            //     })
            //     console.log(res)
            var data = {
                service_id: 'service_59cj2pp',
                template_id: 'template_5mcsuvr',
                user_id: 'L9lrl6lSs1N4ZO7vB',
                template_params: {
                    'name': name,
                    message: `This email is to confirm that '${name} have requested ${selectedService} service
                    ${request && `with a special request of ${request}`}
                    on ${date}
                    Please make sure to contact this person on this email ${email}.`,
                    email: email
                }
            };
              const res = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`
                  },
                  body: JSON.stringify(data)
                })
                console.log(res)
        }
    }
  return (
    <div className="container-fluid bg-secondary booking my-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-6 py-5">
                    <div className="py-5">
                        <h1 className="text-white mb-4">Certified and Award Winning Car Repair Service Provider</h1>
                        <p className="text-white mb-0">We are a team of highly skilled and certified technicians, dedicated to providing top-notch car repair services. With years of experience and numerous awards for our quality service, you can trust us to keep your vehicle in top shape.</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="bg-primary h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
                        <h1 className="text-white mb-4">Book For A Service</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-12 col-sm-6">
                                    <input id="name" value={formData.name} onChange={handleChange} type="text" className="form-control border-0" placeholder="Your Name" style={{height: "55px"}}/>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input id="email" value={formData.email} onChange={handleChange} type="email" className="form-control border-0" placeholder="Your Email" style={{height: "55px"}}/>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <select id="selectedService" value={formData.selectedService} onChange={handleChange} className="form-select border-0" style={{height: "55px"}}>
                                        <option selected>Select A Service</option>
                                        <option value="1">Diagnostic Test</option>
                                        <option value="2">Engine Service</option>
                                        <option value="3">Brake Repair</option>
                                        <option value="4">Regular Maintenance</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="date" id="date1" data-target-input="nearest">
                                        <input type="date"
                                            id="date" value={formData.date}
                                            onChange={handleChange}
                                            className="form-control border-0 datetimepicker-input"
                                            placeholder="Service Date" data-target="#date1" 
                                            data-toggle="datetimepicker" style={{height: "55px"}}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <textarea id="request" value={formData.request} onChange={handleChange} className="form-control border-0" placeholder="Special Request"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-secondary w-100 py-3" type="submit">Book Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BookingForm