import React from 'react'
import BookingForm from '../components/BookingForm'
import FactStates from '../components/FactStates'
import MainFram from '../components/MainFram'
import Services from '../components/Services'
import ServicesView from '../components/ServicesView'

function Home() {
  return (
    <>
        <MainFram />
        <Services />
        <FactStates />
        <ServicesView />
        <BookingForm />
    </>
  )
}

export default Home