import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AboutPage from '../pages/AboutPage'
import ContactPage from '../pages/ContactPage'
import Home from '../pages/Home'
import ServicesPage from '../pages/ServicesPage'

const CustomeRoutes = () => {
    return (
        <Switch>
            <Route path='/' component={Home} exact />
            <Route path='/about' component={AboutPage} exact />
            <Route path='/services' component={ServicesPage} exact />
            <Route path='/contact' component={ContactPage} exact />
            <Route path="/**" component={() => <div>404</div>} />
        </Switch>

    )
}

export default CustomeRoutes
