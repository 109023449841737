import React from 'react'

function Services() {
  return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="d-flex py-5 px-4">
                        <i className="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Quality Servicing</h5>
                            <p>Our auto repair shop provides top-notch servicing for your vehicle to ensure its smooth running. We only use high-quality products and follow industry standards for all repairs and maintenance work.</p>
                            {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="d-flex bg-light py-5 px-4">
                        <i className="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Expert Workers</h5>
                            <p>Our technicians are highly skilled and knowledgeable in all aspects of automotive repair and maintenance. They will diagnose and repair your vehicle to the highest standards, ensuring a safe and reliable ride.</p>
                            {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="d-flex py-5 px-4">
                        <i className="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                        <div className="ps-4">
                            <h5 className="mb-3">Modern Equipment</h5>
                            <p>Our auto repair shop is equipped with the latest tools and equipment to handle any repair job. From simple oil changes to complex engine repairs, we have the technology and equipment to get the job done right.</p>
                            {/* <a className="text-secondary border-bottom" href="">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services